import { Button, Container, Text, VStack } from '@chakra-ui/react';
import React, { useState } from "react";
import SEO from "../components/seo";
const insults: string[] = require('../../insults.json');
const prefixes: string[] = require('../../prefixes.json');

function randomInsult(context: string): string[] {
  const first = insults[Math.floor(Math.random() * insults.length)];
  const second = insults[Math.floor(Math.random() * insults.length)];
  const an = "aeiou".includes(first[0]);
  const article = an ? "an" : "a";

  return [
    `${context} ${article}`,
    first,
    second
  ];
}

function trumpInsult() {
  return randomInsult("Trump is");
}

function Banner() {
  const [insult, setInsult] = useState<string[]>(trumpInsult());

  function onClick(event) {
    setInsult(trumpInsult());
  }

  return (
    <>
      <Container maxWidth="6xl">
        <Button
          size="sm"
          onClick={onClick}
        >Read more</Button>
      </Container>
        <Container maxWidth="6xl" centerContent>
          {
            insult.map(phrase => <Text fontSize="6xl">{phrase}</Text>)
          }
        </Container>
    </>
  )
}

function IndexPage() {
  return (
    <>
      <SEO title="Home" />
      <VStack spacing="8">
        <Banner />
      </VStack>
    </>
  )
}

export default IndexPage
